exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-strony-internetowe-ostroda-js": () => import("./../../../src/pages/strony-internetowe-ostroda.js" /* webpackChunkName: "component---src-pages-strony-internetowe-ostroda-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */)
}

